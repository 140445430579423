<template>
    <div class="mobileEditBill scrolling">
        <mobileAddBill />
    </div>
</template>
<script>
import mobileAddBill from './addBill.vue'
export default {
    components: {
        mobileAddBill,
    },
    data() {
        return {}
    },
}
</script>
<style lang="less" scoped>
.mobileEditBill {
}
</style>
