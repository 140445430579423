<template>
  <div class="mobileAddBill">
    <div class="forms">
      <a-form :form="form">
        <template>
          <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="公文标题" has-feedback>
            <a-input
              placeholder="请输入"
              v-decorator.trim="[
                'biaoti',
                { initialValue: '收文处理笺', rules: [{ required: true, message: '请输入!' }] },
              ]"
            />
          </a-form-item>
          <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="来文机关" has-feedback>
            <a-input placeholder="请输入" v-decorator="['lwjg', { rules: [{ required: true, message: '请输入!' }] }]" />
          </a-form-item>

          <div class="line"></div>
          <a-form-item label="公文分类" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <a-select placeholder="请选择" v-decorator="['xzfn', { rules: [{ required: true, message: '请选择！' }] }]">
              <a-select-option v-for="(item, index) in flow_file_type" :key="index" :value="item.code">{{
                item.value
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="紧急程度" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <a-select placeholder="请选择" v-decorator="['jjcd', { rules: [{ required: true, message: '请选择！' }] }]">
              <a-select-option v-for="(item, index) in flow_urgency_degree" :key="index" :value="item.code">{{
                item.value
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="密级程度" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <a-select placeholder="请选择" v-decorator="['miji', { rules: [{ required: true, message: '请选择！' }] }]">
              <a-select-option v-for="(item, index) in flow_secrecy_degree" :key="index" :value="item.code">{{
                item.value
              }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="收文时间" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <a-date-picker
              v-model="myform.swsj"
              v-decorator="['swsj', { rules: [{ required: true, message: '请选择！' }] }]"
              :disabledDate="disabledDateHandler"
            />
          </a-form-item>

          <div class="line"></div>
          <!-- <a-form-item label="公文标题" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <a-input
              placeholder="请输入"
              v-decorator="['wjbt', { rules: [{ required: true, message: '请输入!' }] }]"
            />
          </a-form-item> -->
          <a-form-item label="拟办意见" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <a-textarea
              :rows="4"
              placeholder="请输入"
              v-decorator="['nbyj', { rules: [{ required: true, message: '请输入!' }] }]"
            ></a-textarea>
          </a-form-item>
          <a-form-item label="文件摘要" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <a-textarea
              :rows="4"
              placeholder="请输入"
              v-decorator="['summary', { rules: [{ required: true, message: '请输入!' }] }]"
            ></a-textarea>
          </a-form-item>
          <div class="showFormMess">
            <a-button type="primary" @click="showFormTable"> 查看表单 </a-button>
          </div>
          <a-form-item label="附件" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
            <slot name="upload">
              <upload :fileIds.sync="fileIds" ref="upload" />
            </slot>
          </a-form-item>
          <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-button @click="handleSubmit(true)" type="primary" style="margin-right: 10px"> 保存并发起审批 </a-button>
            <a-button @click="handleSubmit(false)" type="primary"> 保存登记 </a-button>
          </a-form-item>
        </template>
      </a-form>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import mixin from '../components/minxin'
import upload from '@/views/fileStream/documentManage/upload'
import { documentBillEdit, documentBillAdd, documentBillDetail } from '@/api/modular/fileStream/documentManage'
export default {
  mixins: [mixin],
  components: {
    upload,
  },
  data() {
    return {
      moment,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this),
      fileIds: [],
      myform: {},
    }
  },
  mounted() {
    const myData = { ...this.$store.state.mobile.addBillMess }
    const keys = Object.keys(myData)
    const id = this.$route.query.id
    this.form.setFieldsValue(myData)
    if (keys.length === 0 && id) {
      this.get_info(id)
    }
  },
  methods: {
    showFormTable() {
      const formValue = this.delEmptKey(this.form.getFieldsValue())
      this.$store.commit('mobile/cacheBillMess', formValue)
      this.$router.push({ name: 'mobileShowForm', query: { ...formValue } })
    },
    get_info(id) {
      this.form_id = id
      documentBillDetail({ id }).then((res) => {
        const {
          acceptTime,
          name,
          urgencyDegree,
          agency,
          secrecyDegree,
          deviseOpinion,
          draftId,
          approvalWay,
          // title,
          fileType,
          documentNodeList,
          documentFileList,
          content,
          summary,
          documentNumber,
        } = res.data
        const form = {
          swsj: acceptTime,
          biaoti: name,
          jjcd: String(urgencyDegree),
          lwjg: agency,
          miji: String(secrecyDegree),
          nbyj: deviseOpinion,
          draftId,
          summary,
          spfs: '1' || String(approvalWay),
          // wjbt: title,
          xzfn: String(fileType),
          nodes: documentNodeList,
          fileIds: documentFileList,
          content,
          documentNumber,
        }
        //
        this.fileIds = documentFileList
        this.form.setFieldsValue(form)
      })
    },
    delEmptKey(obj) {
      const objCopy = { ...obj }
      const keys = Object.keys(objCopy)
      for (const index of keys) {
        if (!objCopy[index]) delete objCopy[index]
      }
      return objCopy
    },
    // 处理日期
    disabledDateHandler(current) {
      return current < moment().subtract(1, 'days').endOf('day')
    },
    handleSubmit(isCreateApp) {
      const { content, fileIds } = this
      let func = documentBillAdd
      const id = this.form_id
      if (id) {
        func = documentBillEdit
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          const obj = {
            acceptTime: values.swsj ? moment(values.swsj).format('YYYY-MM-DD') : '',
            name: values.biaoti,
            urgencyDegree: Number(values.jjcd),
            agency: values.lwjg,
            secrecyDegree: Number(values.miji),
            deviseOpinion: values.nbyj,
            approvalWay: Number(values.spfs),
            // title: values.wjbt,
            fileType: Number(values.xzfn),
            fileIds,
            summary: values.summary,
            content,
            documentType: 1,
          }
          if (id) {
            obj.id = id
          }
          func(obj).then((res) => {
            if (!res.success) {
              return
            }
            this.$message.success('登记成功')
            this.form.resetFields()
            this.$store.commit('mobile/cacheBillMess', {})
            if (id) {
              this.$router.go(-1)
            } else if (isCreateApp) {
              this.$router.push({ name: 'mobileCreateApproval', query: { id: res.data } })
            } else {
              this.$emit('changeTab')
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.mobileAddBill {
  .forms {
    padding: 24px;
    .line {
      height: 1px;
      border: 1px dashed #3b9afa;
      display: block;
      width: 100%;
      margin: 30px 0;
    }
    .showFormMess {
      text-align: right;
    }
  }
}
</style>
