1<template>
  <div>
    <template v-if="isOnlyShow">
      <div v-for="(o, i) in fileName" :key="i">
        {{ o.fileName }}
        <template>
          <a-tooltip v-if="$store.state.app.device !== DEVICE_TYPE.MOBILE">
            <template slot="title"> 下载 </template>
            <a-button type="link" icon="download" @click="download(o)" />
          </a-tooltip>
          <a-tooltip v-if="fileType.some((j) => o.fileName.endsWith(j))">
            <template slot="title"> 预览 </template>
            <a-button type="link" icon="eye" @click="preView(o)" />
          </a-tooltip>
        </template>
        <!-- <eye-outlined /> -->
      </div>
    </template>
    <template v-else>
      <a-upload :customRequest="customRequest" :openFileDialogOnClick="!loading" :showUploadList="false" name="file">
        <a-button class="upbt font" type="primary" ghost icon="cloud-download" :loading="loading">上传附件</a-button>
      </a-upload>
      <div v-for="(o, i) in fileName" :key="i">
        {{ o.fileName }} <a-button type="link" icon="delete" @click="delDom(i)" />
      </div>
    </template>
  </div>
</template>

<script>
import { sysFileInfoDownload, sysFileInfoUpload, sysFileWditAccept } from '@/api/modular/system/fileManage'
import { serverUrl } from '@/mixins/config.js'
import { DEVICE_TYPE } from '@/utils/device'
export default {
  data() {
    return {
      fileName: [],
      loading: false,
      DEVICE_TYPE,
      accept: ['.doc', '.docx', '.image/*', '.pdf', '.xls', '.xlsx'],
      fileType: ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'],
    }
  },
  props: {
    fileIds: {
      type: Array,
      default() {
        return []
      },
    },
    officialId: {
      type: String,
      default: '',
    },
    isOnlyShow: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fileName = this.fileIds
  },
  watch: {
    fileIds(n) {
      this.fileName = n
    },
  },
  methods: {
    /**
     * 上传文件
     */
    customRequest(data) {
      const size = data.file.size / 1024 / 1024 > 100
      const fileName = data.file.name
      const fileType = ['.doc', '.docx', '.pdf', '.xls', 'xlsx', '.png', '.jpg'] // .doc,.docx,image/*,.pdf,.xls,.xlsx
      // if (!fileType.some(j => fileName.endsWith(j))) {
      //   this.$message.error('只能传pdf、word、excel、png、jpg')
      //   return
      // }
      if (size) {
        this.$message.error('上传失败：文件大于100M')
        return
      }
      if (this.loading) return
      this.loading = true
      const formData = new FormData()
      formData.append('file', data.file)
      sysFileInfoUpload(formData)
        .then((res) => {
          if (res.success) {
            this.$message.success('上传成功')
            //
            this.fileName.push({ fileName: data.file.name, fileId: res.data })
            //
            this.$emit('update:fileIds', this.fileName)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    editAcceptBill(data) {
      sysFileWditAccept({
        ...data,
        billId: this.$route.query.id || this.officialId,
      })
        .then((res) => {
          if (res.code === 200) {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    delDom(i) {
      this.fileName.splice(i, 1)
    },
    download(record) {
      this.cardLoading = true
      sysFileInfoDownload({ id: record.fileId })
        .then((res) => {
          this.cardLoading = false
          this.editAcceptBill({ downloadStatus: 1 })
          this.downloadfile(res)
          // eslint-disable-next-line handle-callback-err
        })
        .catch(() => {
          this.cardLoading = false
          this.$message.error('下载错误：获取文件流错误')
        })
    },
    preView(record) {
      this.editAcceptBill({ seeStatus: 1 })
      if (this.$store.state.app.device === DEVICE_TYPE.MOBILE) {
        try {
          this.editAcceptBill({ downloadStatus: 1 })
          wx.previewFile({
            url: record.fileUrl,
            name: record.fileName,
            size: Number(record.fileSizeKb) * 1024,
          })
        } catch {}
        return
      }
      if (this.getFileType(record.fileUrl)) return this.preViewFile(record.fileUrl)
      const i = record.fileUrl.indexOf('8080')
      window.open(
        `https://view.officeapps.live.com/op/view.aspx?src=http://${serverUrl}/api${record.fileUrl.substring(i + 4)}`
      )
    },
    getFileType(filename) {
      return ['.pdf', '.png', '.jpeg', '.gif', '.jpg'].some((j) => filename.endsWith(j))
    },
    preViewFile(href) {
      window.open(href, '_blank')
    },
    creatUrl(res) {
      var blob = new Blob([res.data], { type: 'application/octet-stream;charset=UTF-8' })
      var contentDisposition = res.headers['content-disposition']
      var patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
      var result = patt.exec(contentDisposition)
      var filename = result[1]
      var href = window.URL.createObjectURL(blob) // 创建下载的链接
      return { filename, href }
    },
    downloadfile(res) {
      var { filename, href } = this.creatUrl(res)
      var downloadElement = document.createElement('a')
      var reg = /^["](.*)["]$/g
      downloadElement.style.display = 'none'
      downloadElement.href = href
      downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() // 点击下载
      document.body.removeChild(downloadElement) // 下载完成移除元素
      window.URL.revokeObjectURL(href)
    },
    empty() {
      this.fileName = []
    },
  },
}
</script>

<style lang="less" scoped>
.font {
  font-size: 16px;
}
.upbt {
  width: 310px;
  height: 58px;
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
}
</style>
